/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {L} from "@src/style/mdxStyle.js";
import ReactCompareImage from "react-compare-image";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "5-приёмов-работы-с-яркостными-масками",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-%D0%BF%D1%80%D0%B8%D1%91%D0%BC%D0%BE%D0%B2-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D1%8B-%D1%81-%D1%8F%D1%80%D0%BA%D0%BE%D1%81%D1%82%D0%BD%D1%8B%D0%BC%D0%B8-%D0%BC%D0%B0%D1%81%D0%BA%D0%B0%D0%BC%D0%B8",
    "aria-label": "5 приёмов работы с яркостными масками permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5 приёмов работы с яркостными масками"), "\n", React.createElement(_components.p, null, "Яркостные маски - очень удобный инструмент, но, как и любой другой инструмент, использовать их можно по-разному. Основы работы с обычными и яркостными масками я описал в ", React.createElement(L, {
    t: "бесплатном курсе Основы Яркостных Масок",
    h: "https://arcpanel.averin.photo/ru/blog/2020-03-15-luminosity-masking-basics-00-overview/"
  }), ". В этой статье я хочу показать вам 5 более сложных приёмов работы с яркостными масками, о которых вы, скорее всего, не слышали."), "\n", React.createElement(_components.p, null, "Вы уже, наверняка, знаете, что яркостные маски сильно помогают при сведении экспозиций, при работе с деталями или при создании реалистичных iHDR изображений. Но есть множество других приёмов работы с яркостными маками."), "\n", React.createElement(_components.p, null, "Для удобства я буду создавать маски с помощью ARCPanel. О том, как создавать яркостные маски вручную можно прочитать в ", React.createElement(L, {
    t: "этой статье",
    h: "https://arcpanel.averin.photo/ru/blog/2020-05-07-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "ограничение-яркостных-масок",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BE%D0%B3%D1%80%D0%B0%D0%BD%D0%B8%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D1%8F%D1%80%D0%BA%D0%BE%D1%81%D1%82%D0%BD%D1%8B%D1%85-%D0%BC%D0%B0%D1%81%D0%BE%D0%BA",
    "aria-label": "ограничение яркостных масок permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ограничение яркостных масок"), "\n", React.createElement(_components.p, null, "Начать предлагаю с самого простого и, при этом, самого популярного, приёма."), "\n", React.createElement(_components.p, null, "Если обернуть эффект с яркостной маской в группу, то можно ограничить эффект маски через маску группы."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[0].publicURL,
    rightImage: props.data.mdx.frontmatter.images[1].publicURL
  }), "\n", React.createElement(_components.p, null, "Очень простой приём с богатыми возможностями."), "\n", React.createElement(_components.p, null, "Подробнее о том как им пользоваться вы узнаете в ", React.createElement(L, {
    t: "этой статье",
    h: "https://arcpanel.averin.photo/ru/blog/2020-05-22-limiting-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "убираем-небо-в-лесу",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D1%83%D0%B1%D0%B8%D1%80%D0%B0%D0%B5%D0%BC-%D0%BD%D0%B5%D0%B1%D0%BE-%D0%B2-%D0%BB%D0%B5%D1%81%D1%83",
    "aria-label": "убираем небо в лесу permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Убираем небо в лесу"), "\n", React.createElement(_components.p, null, "Лесные фотографии всегда радуют глаз и чаще всего в лесу мы строим композицию так чтобы неба не было в кадре. Но, как бы мы ни старались, небо всё равно может просвечивать между листьев и веток, отвлекая от остальной части кадра."), "\n", React.createElement(_components.p, null, "К счастью эти яркие световые пятна можно исправить обработкой."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[2].publicURL,
    rightImage: props.data.mdx.frontmatter.images[3].publicURL
  }), "\n", React.createElement(_components.p, null, "Вот ", React.createElement(L, {
    t: "тут",
    h: "https://arcpanel.averin.photo/blog/2020-05-22-remove-sky-with-luminosity-masks/"
  }), " можно почитать подробнее как это работает."), "\n", React.createElement(_components.h3, {
    id: "небольшая-сноска",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%BD%D0%B5%D0%B1%D0%BE%D0%BB%D1%8C%D1%88%D0%B0%D1%8F-%D1%81%D0%BD%D0%BE%D1%81%D0%BA%D0%B0",
    "aria-label": "небольшая сноска permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Небольшая сноска"), "\n", React.createElement(_components.p, null, "Описаные далее приёмы я отношу к более \"креативным\". Я понимаю что многие из этих приёмов довольно спорны и не каждый фотограф готов применять их в своих работах."), "\n", React.createElement(_components.h2, {
    id: "реалистичные-лучи-от-солнца",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D1%81%D1%82%D0%B8%D1%87%D0%BD%D1%8B%D0%B5-%D0%BB%D1%83%D1%87%D0%B8-%D0%BE%D1%82-%D1%81%D0%BE%D0%BB%D0%BD%D1%86%D0%B0",
    "aria-label": "реалистичные лучи от солнца permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Реалистичные лучи от солнца"), "\n", React.createElement(_components.p, null, "Вы наверняка видели ролики на YouTube, которые показывают как добавить солнечные лучи на фотографию. Яркостные маски могут сделать такие лучи гораздо более реалистичными."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[4].publicURL,
    rightImage: props.data.mdx.frontmatter.images[5].publicURL
  }), "\n", React.createElement(_components.p, null, "Подробнее об этом приёмы вы можете узнать из ", React.createElement(L, {
    t: "этой статьи",
    h: "https://arcpanel.averin.photo/ru/blog/2020-05-22-realistic-sun-rays-with-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "живописно-мягкие-тени",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B6%D0%B8%D0%B2%D0%BE%D0%BF%D0%B8%D1%81%D0%BD%D0%BE-%D0%BC%D1%8F%D0%B3%D0%BA%D0%B8%D0%B5-%D1%82%D0%B5%D0%BD%D0%B8",
    "aria-label": "живописно мягкие тени permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Живописно-мягкие тени"), "\n", React.createElement(_components.p, null, "В работах многих известных пейзажистов вы можете увидеть живописно-мягкие тени. Есть много способов и приёмов получить такие тени, в частности, с помощью яркостных масок."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[4].publicURL,
    rightImage: props.data.mdx.frontmatter.images[6].publicURL
  }), "\n", React.createElement(_components.p, null, "Подробнее об этом эффекте можно узнать из ", React.createElement(L, {
    t: "этой статьи",
    h: "https://arcpanel.averin.photo/ru/blog/2020-05-22-painterly-soft-shadows-with-luminosity-masks/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "тонирование-в-adobe-photoshop-не-хуже-чем-в-davinci-resolve",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D1%82%D0%BE%D0%BD%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B2-adobe-photoshop-%D0%BD%D0%B5-%D1%85%D1%83%D0%B6%D0%B5-%D1%87%D0%B5%D0%BC-%D0%B2-davinci-resolve",
    "aria-label": "тонирование в adobe photoshop не хуже чем в davinci resolve permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Тонирование в Adobe Photoshop не хуже, чем в Davinci Resolve"), "\n", React.createElement(_components.p, null, "Цветовое тонирование это очень важный аспект при работе с видео. Как в фотографии обязательна проявка из RAW так в видеографии обязательно цветовое тонирование (грейдинг) исходного видеоматериала. Это даёт видеографу возможность передать определённые эмоции в видеоряде."), "\n", React.createElement(_components.p, null, "Де факто, Davinci Resolve - лучшая програма для грейдинга видео. С помощью яркостных масок вы можете получить очень похожий по удобству инструмент и в Adobe Photoshop."), "\n", React.createElement(ReactCompareImage, {
    leftImage: props.data.mdx.frontmatter.images[4].publicURL,
    rightImage: props.data.mdx.frontmatter.images[7].publicURL
  }), "\n", React.createElement(_components.p, null, "Полное описание и необходимые шаги описаны в ", React.createElement(L, {
    t: "этой статье",
    h: "https://arcpanel.averin.photo/ru/blog/2020-05-22-color-grade-in-photoshop-like-in-davinci-resolve/"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "заключение",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%D0%B7%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B5%D0%BD%D0%B8%D0%B5",
    "aria-label": "заключение permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Заключение"), "\n", React.createElement(_components.p, null, "Есть множество разных приёмов и техник при работе с масками. Некоторые из описаных в статье приемов я придумал сам чтобы показать вам на примере как можно экспериментировать с масками."), "\n", React.createElement(_components.p, null, "Вы знаете другие интересные приёмы при работе с масками? Я буду рад гостевым статьям в блоге Яркостных Масок."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
